import { render, staticRenderFns } from "./StudyMaterialsLayout.vue?vue&type=template&id=08d27d80&scoped=true&"
import script from "./StudyMaterialsLayout.vue?vue&type=script&lang=js&"
export * from "./StudyMaterialsLayout.vue?vue&type=script&lang=js&"
import style0 from "./StudyMaterialsLayout.vue?vue&type=style&index=0&id=08d27d80&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08d27d80",
  null
  
)


    import installComponents from "!/Users/jurajpetras/dev/pmat/web/frontend/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BNavItem, BNav, BCol, BRow} from 'bootstrap-vue'
    installComponents(component, {BNavItem, BNav, BCol, BRow})
    

export default component.exports